import React, { Fragment, useEffect } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';
import moment from "moment";


export default ({rows,refresh,rate}) => {
 
  const getDate = (value) =>{
   return moment((parseInt(value) * 1000)+(946684800*1000)).format('LLL') 
  }

  return (
    <Fragment>
      <Title>Recent Escrows</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Cancel At</TableCell>
            <TableCell>Condition</TableCell>
            <TableCell align="right">Payed Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.PreviousTxnLgrSeq}</TableCell>
              <TableCell>{getDate(row.CancelAfter)}</TableCell>
              <TableCell>Pending</TableCell>
              <TableCell align="right">{new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'XRP' }).format(
                parseFloat(row.Amount) / 1000000
              )} ({new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(
                parseFloat(row.Amount) / 1000000 * rate
              )})</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button color="primary" onClick={refresh}>
        <RefreshIcon  variant="button" />
        Refresh
      </Button>
    </Fragment>
  );
}
