// src/redux/actions/authActions.js
import { auth } from '../TrustMe/services/firebase'
import axios from 'axios';

const API_URL = 'http://localhost:3000'; // Update this to your backend URL
axios.defaults.baseURL = API_URL;

export const request = (email, idToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
  axios.defaults.headers.common['email'] = email;
  return axios
}

// Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SIGNUP_SUCCESS = 'LOGIN_SUCCESS';

// Action Creators
export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user
});

export const signupSuccess = (user) => ({
  type: SIGNUP_SUCCESS,
  payload: user
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

// Thunk Actions

export const signup = async (user) => {
  try {
    let idToken = await user.getIdToken(true)
    const client = request(user.email, idToken)
    let response = await client.post('/account/create', { email: user.email });
    response = response.data
  } catch (error) {
    console.log(error)
  }

};

export const logout = () => async (dispatch) => {
  try {
    await auth.signOut();
    dispatch(logoutSuccess());
  } catch (error) {
    console.error('Error logging out:', error);
  }
};
