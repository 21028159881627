// src/redux/reducers/authReducer.js
import { BALANCE_SUCCESS,RATE_SUCCESS,INVENTORY_SUCCESS, inventory } from './accountActions';


const initialState = {
  accounts: [],
  rates:{},
  inventory:[]
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case BALANCE_SUCCESS:
      return { ...state, accounts: action.payload };
    case RATE_SUCCESS:
      return {...state,rates:action.payload}
    case INVENTORY_SUCCESS:
      return {...state,inventory:action.payload}
    default:
      return state;
  }
};

export default accountReducer;