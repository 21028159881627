// src/UserDetails.js
import React from 'react';
import { Typography, Paper, Avatar,IconButton } from '@mui/material';
import { auth } from '../../services/firebase';
import CloseIcon from '@mui/icons-material/Close';

const UserDetails = ({handleClose}) => {
    const currentUser = auth.currentUser

    if (!currentUser) {
        return null;
    }

    return (
        <Paper style={{ padding: '20px', display: 'flex', alignItems: 'center' }}>
            <IconButton
                sx={{ position: 'absolute', top: 1, right: 3, border: '1px solid #0f77d9' }}
                color='error'
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
            <Avatar src={currentUser.photoURL} alt={currentUser.displayName} style={{ marginRight: '20px' }} />
            <div>
                <Typography variant="h6">{currentUser.displayName}</Typography>
                <Typography variant="body1">{currentUser.email}</Typography>
            </div>
        </Paper>
    );
};

export default UserDetails;
