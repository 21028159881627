import React from 'react'
import App from './TrustMe/App'
import { createRoot } from 'react-dom/client';
import './style.css'
import { auth } from './TrustMe/services/firebase'


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


const renderApp =()=>{
    root.render(<App tab="home" />);
}

auth.onAuthStateChanged((user) => { 
    if (user) { 
        renderApp()
    } else { 
        renderApp()
    } 
});







