import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default ({ handleClose, products = [] }) => {

  return (
    <Box component="section" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <IconButton
        sx={{ position: 'absolute', top: 1, right: 3, border: '1px solid #0f77d9' }}
        color='error'
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> <Typography color="primary" variant="h6">Image</Typography></TableCell>
              <TableCell><Typography color="primary" variant="h6">Title</Typography></TableCell>
              <TableCell><Typography color="primary" variant="h6">Price</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(product => (
              <TableRow key={product.id}>
                <TableCell>
                  <img src={product.imageUrls[0]} alt={product.title} style={{ width: '100px', height: 'auto' }} />
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{product.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(
                    product.price)}/{product.unit}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};


