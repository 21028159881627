import React, { Fragment, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Title from './Title';
import moment from "moment";
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';




export default function Deposits({ balance, refresh }) {


  return (
    <Fragment>
      <Title>Balance</Title>
      <Typography component="p" variant="h5">
        {balance.xrp} ({balance.exchange})
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on {moment().format('LLL')}
      </Typography>
      <div>
        <Button color="primary" onClick={refresh}>
          <RefreshIcon /> Refresh
        </Button>
      </div>
    </Fragment>
  );
}
