import { request } from './authActions'
import axios from 'axios';
import { collection, getDocs } from 'firebase/firestore';
import { db, storage } from '../TrustMe/services/firebase';

// Action Types
export const BALANCE_SUCCESS = 'BALANCE_SUCCESS';
export const ESCROWS_SUCCESS = 'ESCROWS_SUCCESS';
export const RATE_SUCCESS = 'RATE_SUCCESS';
export const INVENTORY_SUCCESS = 'INVENTORY_SUCCESS';

// Action Creators

export const balanceSuccess = (accounts) => ({
    type: BALANCE_SUCCESS,
    payload: accounts
})

export const rateSuccess = (rates) => ({
    type: RATE_SUCCESS,
    payload: rates
})

export const escrowSuccess = (escrows) => ({
    type: ESCROWS_SUCCESS,
    payload: escrows
})

export const inventorySuccess = (products) => ({
    type: INVENTORY_SUCCESS,
    payload: products
})

// Thunk Actions
export const balance = (email, idToken) => async (dispatch) => {
    try {
        const ratesRespone = await fetch('https://latest.currency-api.pages.dev/v1/currencies/xrp.json')
        const rates = await ratesRespone.json();
        dispatch(rateSuccess(rates.xrp))

        const client = request(email, idToken)
        const response = await client.get('/account/balance');
        const accounts = response.data
        dispatch(balanceSuccess(accounts))
    } catch (error) {
        console.log('Error getting balance:', error);
    }
};

// Thunk Actions
export const escrows = (email, idToken) => async (dispatch) => {
    try {
        const client = request(email, idToken)
        const response = await client.get('/account/escrows');
        const escrows = response.data
        dispatch(escrowSuccess(escrows))

    } catch (error) {
        console.log('Error getting escrows:', error);
    }
};

// Thunk Actions
export const inventory = (id) => async (dispatch) => {
    try {
        const querySnapshot = await getDocs(collection(db, 'products',`${id}`,'inventory'));
        const products = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        })).filter(product => product.active);

       dispatch(inventorySuccess(products))

    } catch (error) {
        console.log('Error getting inventory:', error);
    }
};