import React, { useState,Fragment } from 'react';
import {  Typography, List, ListItemButton, ListItemIcon, ListItemText, Modal, Box, IconButton, Container } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ListIcon from '@mui/icons-material/List';
import Title from './Title';
import AddShoppingCartIcon from '@mui/icons-material/Add';
import ProductForm from './ProductForm'
import ProductList from './ProductList'
import Profile from './UserDetails'

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #0f77d9',
    boxShadow: 24,
    p: 4,
};

const AppMenuCard = ({products}) => {
    const [openModal, setOpenModal] = useState(null);
   

    const handleOpen = (index) => {
        setOpenModal(index);
    };

    const handleClose = () => {
        setOpenModal(null);
    };


    const menuItems = [
        { text: 'Profile', icon: <ContactMailIcon />, content: <Profile handleClose={handleClose} /> },
        { text: 'Inventory', icon: <ListIcon />, content: <ProductList products={products} handleClose={handleClose} /> },
        { text: 'New Product', icon: <AddShoppingCartIcon />, content: <ProductForm handleClose={handleClose} /> }
    ];

    return (
            <Fragment>
                <Title>Menu</Title>
                <List 
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap:'wrap',
                    flexGrow: 1
                  }}
                >
                    {menuItems.map((item, index) => (
                        <ListItemButton  key={index} onClick={() => handleOpen(index)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    ))}
                </List>
                {menuItems.map((item, index) => (
                    <Modal
                        key={index}
                        open={openModal === index}
                        onClose={handleClose}
                        aria-labelledby={`modal-title-${index}`}
                        aria-describedby={`modal-description-${index}`}
                    >
                        <Box sx={modalStyle}>       
                                <Container maxWidth="sm">
                                    <Typography variant="h4" component="h1" gutterBottom>
                                    {item.text}
                                    </Typography>
                                    {item.content}
                                </Container>
                        </Box>
                    </Modal>
                ))}
            </Fragment>
    );
};

export default AppMenuCard;
