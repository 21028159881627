
export const questions = [
    {
        id: 1,
        question: "How can I sell my Precious Mineral on this website?",
        answer: "To sell your Precious Minerals on our website, you need to create an account and list your items for sale. Once a buyer is interested in purchasing your items, you will be notified and can proceed with the transaction."
    },
    {
        id: 2,
        question: "What types of Precious Minerals can I sell on this website?",
        answer: "You can sell a wide variety of rough gemstones and gold on our website. This includes but is not limited to diamonds, emeralds, rubies, sapphires, and various types of gold."
    }, {
        id: 3,
        question: "How do I purchase Precious Minerals as a business?",
        answer: "As a business, you can explore the buyer portal to find the items you’re interested in. Once you’ve made your selections, you can initiate the purchase process. Our cutting-edge technology guarantees smooth and secure payments, ensuring that transactions are both seamless and efficient."
    }, {
        id: 4,
        question: "Is there a fee for selling items on this website?",
        answer: "Yes, our website charges a monthly fee for listing and selling items, along with a percentage commission on sales. The specific fee structure will be detailed in the contract you sign with us."
    }, {
        id: 5,
        question: "How do I ensure the authenticity and quality of the Precious Minerals?",
        answer: "We encourage sellers to provide detailed descriptions and images of their items to ensure transparency. Additionally, buyers have the option to request certifications or third-party evaluations before finalizing a purchase."
    }
]


export const galleries = [
    "https://www.bamitech.net/images/Bamitech-2023354216.jpg",
    "https://www.bamitech.net/images/Bamitech-909163636.jpg",
    "https://www.bamitech.net/images/Bamitech-1467619937.jpg",
    "https://www.bamitech.net/images/IMG_20241001_133934.jpg"
]



