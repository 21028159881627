// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import {getStorage} from 'firebase/storage'
import {getFirestore} from 'firebase/firestore'


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAeiNbKq8TO0btmpq42q8L89BdSR-vDi0Q",
  authDomain: "bamitech.firebaseapp.com",
  databaseURL: "https://bamitech-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bamitech",
  storageBucket: "bamitech.appspot.com",
  messagingSenderId: "603811556433",
  appId: "1:603811556433:web:c74e608a18e397926fdc70"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)
export const auth = getAuth(firebase);
export const db = getFirestore(firebase)
export const storage = getStorage(firebase)
export default firebase