import React, { useState } from 'react';
import { Modal, Box, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// The path to `package.json` can be changed depending the path of current file


const PdfModalViewer = ({ pdfUrl,setDoc,docs}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = (documentName) => {
        setDoc(docs[documentName])
        setOpen(true)
    };
    const handleClose = () => setOpen(false);



    // Create an instance of the default layout plugin
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    // Modal style
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto', // To enable scrolling inside the modal for large PDFs
        position: 'relative', // To position the close button
    };

    // Style for the close icon button
    const closeButtonStyle = {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 1000,
    };

    return (
        <div>
            {/* Button to trigger the modal */}
            <div className="home-container33">
                <a className='home-text61' href='#' onClick={() =>handleOpen('terms')}>
                    Terms and conditions
                </a>
                &nbsp;&nbsp;
                <a className='home-text61' href='#' onClick={() =>handleOpen('privacy')}>
                    Privacy Policy
                </a>
            </div>
            {/* Material UI Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="pdf-viewer-modal"
                aria-describedby="pdf-viewer-description"
            >
                <Box sx={modalStyle}>
                    {/* Close Icon Button */}
                    <IconButton
                        onClick={handleClose}
                        sx={closeButtonStyle}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* PDF Viewer */}
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    </Worker>
                </Box>
            </Modal>
        </div>
    );
};

export default PdfModalViewer;
