import React, { useState } from 'react'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import GalleryCard3 from '../components/gallery-card3'
import Question from '../components/question'
import Logo from '../assets/logo.png'
import ContactFormModal from '../components/ContactFormModal'
import RegistrationModal from '../components/RegistrationModal'
import Tooltip from '@mui/material/Tooltip';
import { questions, galleries } from '../resources/fag'
import TermsAndPolicy from '../TrustMe/components/dashboard/PDFViewer'
import './home.css'

const docs = {
  terms: '/TERMS_AND_CONDITIONS _V1.pdf',
  privacy: '/Privacy_Policy_V1.pdf'
}

const Home = (props) => {
  const [openSignup, setOpenSignup] = useState(false)
  const [plan, setPlan] = useState('')
  const [doc, setDoc] = useState(docs.term)

  const date = new Date()
  const price = {
    free: 9.99,
    basic: 49.99,
    pro: 199.99
  }

  const register = (planValue) => {
    setPlan(planValue)
    setOpenSignup(true)
  }

  const marketplace = ""

  const planMessage = `Please note that your registration is subject to passing our seller verification process. We will notify you once the verification is complete. Thank you for your understanding!`

  return (
    <div className="home-container">
      <Helmet>
        <title>Bamitech</title>
        <meta property="og:title" content="Bamitech" />
      </Helmet>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <img src={Logo} className="home-logo" alt="Bamitech" />
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links">
              <span>Home</span>
              <a href={`${marketplace}/login`} className="home-nav2">Sell Precious Minerals</a>
              <a href={`${marketplace}/login`} className="home-nav3">Buy Precious Minerals</a>
              <a href="#home-faq" className="home-nav4">How It Works</a>
              <span className="home-nav5">
                <ContactFormModal />
              </span>
            </nav>
            <div className="home-buttons">
              <a href={`${marketplace}/login`}>
                <button className="home-login button">Login</button>
              </a>
              <a href="#registration">
                <button className="home-register button">Register</button>
              </a>
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <img src={Logo} className="home-logo" alt="Bamitech" />
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links1">
                <span className="home-nav11">Home</span>
                <a href={`${marketplace}/login`} className="home-nav21">Sell Precious Minerals</a>
                <a href={`${marketplace}/login`} className="home-nav31">Buy Precious Minerals</a>
                <a href="#home-faq" className="home-nav41">How It Works</a>
                <span className="home-nav51">
                  <ContactFormModal />
                </span>

              </nav>
              <div className="home-buttons1">
                <a href={`${marketplace}/login`}>
                  <button className="home-login1 button">Login</button>
                </a>
                <a href="#registration">
                  <button className="home-register1 button">Register</button>
                </a>
              </div>
            </div>
            <div>
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="home-hero">
        <div className="home-hero1">
          <div className="home-container01">
            <h1 className="home-hero-heading heading1">
              Sell Your Precious Minerals
            </h1>
            <span className="home-hero-sub-heading">
              Precious Minerals Marketplace
            </span>
            <div className="home-btn-group">
              <a href="#home-pricing">
                <button className="home-hero-button1 button">Get Started</button>
              </a>
              <a href="#home-features">
                <button className="home-hero-button1 button">Learn More →</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-details">
        <div className="home-details1">
          <div className="home-container02">
            <span className="home-text sectionTitle">
              <span>Details</span>
              <br></br>
            </span>
            <h2 className="home-details-heading heading2">
              Unlock the Value of Your Precious Minerals
            </h2>
            <span className="home-details-sub-heading">
              Join Our Global Trading Platform for Precious Minerals. Connect with buyers and sellers worldwide on our platform, designed to provide a secure and efficient trading experience for precious minerals. With a user-friendly interface and robust features, you can effortlessly showcase your products, negotiate deals, and expand your business.
            </span>
          </div>
          <img
            alt="image"
            src="https://www.bamitech.net/images/Bamitech-1467619937.jpg"
            className="home-details-image"
          />
        </div>
      </div>
      <div id="home-features" className="home-features">
        <div className="home-features-container">
          <div className="home-features1">
            <div className="home-container03">
              <span className="home-text03 sectionTitle">
                <span>features</span>
                <br></br>
              </span>
              <h2 className="home-features-heading heading2">Key Features</h2>
              <span className="home-features-sub-heading">
                Discover the benefits of our platform
              </span>
            </div>
            <div className="home-container04">
              <FeatureCard
                heading="User-friendly interface"
                subHeading="Easily navigate through the website and find what you need"
              ></FeatureCard>
              <FeatureCard
                heading="Secure transactions"
                subHeading="Safely Buy and Sell Precious Minerals. Utilize our innovative and secure system, featuring robust verification of participants and a protected payment workflow, ensuring the safety and confidence of both buyers and sellers"
              ></FeatureCard>
              <FeatureCard
                heading="Discover a Diverse Selection"
                subHeading="Explore a wide range of precious minerals from various sellers, offering you unparalleled choices and opportunities in the market"
              ></FeatureCard>
              <FeatureCard
                heading="Ensure Trust and Authenticity"
                subHeading="Gain confidence in your transactions through our Verified Seller Program, designed to ensure the credibility and authenticity of every seller on our platform"
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div id="home-pricing" className="home-pricing">
        <div className="home-pricing1">
          <div className="home-container05">
            <span className="home-text06 sectionTitle">
              <span>Pricing</span>
              <br></br>
            </span>
            <h2 id="registration" className="home-pricing-heading heading2">
              Choose the Right Plan for You
            </h2>
            <span className="home-pricing-sub-heading">
              Choose a Pricing Plan That Fits Your Needs and Start Trading Today
            </span>
          </div>
          <div className="home-container06">
            <div className="home-pricing-card">
              <div className="home-container07">
                <span className="home-text09 heading3">Starter Plan</span>
                <span className="home-free-plan-description">
                  Harness our cutting-edge technology to ensure smooth and secure payments, making transactions seamless and efficient.
                  Our Starter Plan, designed specifically for individuals looking to sell their precious minerals. It’s the perfect way to enter the market and showcase your products
                </span>
              </div>
              <div className="home-container08">
                <span className="home-text10">
                  <span>€</span>
                  <span></span>
                </span>
                <span className="home-free-plan-price">{price.free}</span>
                <span className="home-text21">/ month</span>
              </div>
              <div className="home-container09">
                <div className="home-container10">
                  <span className="home-text13">✔</span>
                  <span className="home-free-plan-features">
                    Listing up to 2 products
                  </span>
                </div>
                <div className="home-container11">
                  <span className="home-text14">✔</span>
                  <span className="home-free-plan-features1">
                    Limited access to buyer inquiries
                  </span>
                </div>
                <div className="home-container12">
                  <span className="home-text15">✔</span>
                  <span className="home-free-plan-features2">
                    Basic customer support
                  </span>
                </div>
                <div className="home-container13">
                  <span className="home-text16">✔</span>
                  <span className="home-free-plan-features3">
                    Starter Plan Feature
                  </span>
                </div>
              </div>

              <button onClick={() => register('Starter')} className="home-button1 button">Try Starter Plan</button>

            </div>
            <div className="home-pricing-card1">
              <div className="home-container14">
                <span className="home-text17 heading3">Basic Plan</span>
                <span className="home-basic-plan-description">
                  Harness our cutting-edge technology to ensure smooth and secure payments, making transactions seamless and efficient.
                  Our Basic Plan is ideal for individuals and small businesses ready to sell their precious minerals. It's an affordable and effective way to reach buyers and grow your business
                </span>
              </div>
              <div className="home-container15">
                <span className="home-text18">
                  <span>€</span>
                  <span></span>
                </span>
                <span className="home-basic-plan-pricing">{price.basic}</span>
                <span className="home-text21">/ month</span>
              </div>
              <div className="home-container16">
                <div className="home-container17">
                  <span className="home-text22">✔</span>
                  <span className="home-text23">All features of Starter plan</span>
                </div>
                <div className="home-container18">
                  <span className="home-text24">✔</span>
                  <span className="home-basic-plan-features">
                    Listing up to 50 products
                  </span>
                </div>
                <div className="home-container19">
                  <span className="home-text25">✔</span>
                  <span className="home-basic-plan-features1">
                    Access to buyer inquiries
                  </span>
                </div>
                <div className="home-container20">
                  <span className="home-text26">✔</span>
                  <span className="home-basic-plan-features2">
                    Priority customer support
                  </span>
                </div>
                <div className="home-container21">
                  <span className="home-text27">✔</span>
                  <span className="home-basic-plan-features3">
                    Basic Plan Feature
                  </span>
                </div>
              </div>

              <button onClick={() => register('Basic')} className="home-button1 button">
                Try Basic plan
              </button>

            </div>
            <div className="home-pricing-card2">
              <div className="home-container22">
                <span className="home-text28 heading3">
                  <span>Business Plan</span>
                  <br></br>
                </span>
                <span className="home-pro-plan-description">
                  Harness our cutting-edge technology to ensure smooth and secure payments, making transactions seamless and efficient.
                  Our Professional Plan is designed for businesses to maximize their precious mineral sales with advanced features and robust support
                </span>
              </div>
              <div className="home-container23">
                <span className="home-text31">
                  <span>€</span>
                  <span></span>
                </span>
                <span className="home-pro-plan-pricing">{price.pro}</span>
                <span className="home-text34">/ month</span>
              </div>
              <div className="home-container24">
                <div className="home-container25">
                  <span className="home-text35">✔</span>
                  <span className="home-text36">
                    All features of BASIC plan
                  </span>
                </div>
                <div className="home-container26">
                  <span className="home-text37">✔</span>
                  <span className="home-pro-plan-features">
                    Unlimited product listings
                  </span>
                </div>
                <div className="home-container27">
                  <span className="home-text38">✔</span>
                  <span className="home-pro-plan-features1">
                    Access to buyer inquiries
                  </span>
                </div>
                <div className="home-container28">
                  <span className="home-text39">✔</span>
                  <span className="home-pro-plan-features2">
                    Dedicated account manager
                  </span>
                </div>
              </div>
              <button onClick={() => register('Business')} className="home-button2 button">Try Business plan</button>
            </div>
          </div>
        </div>
      </div>
      <div className="home-gallery">
        <div className="home-gallery1">
          <h1 className="home-gallery-heading heading2">
            Explore Collections
          </h1>
          <span className="home-gallery-sub-heading">
            Discover the beauty of precious minerals
          </span>
          <div className="home-container29">
            {
              galleries.map((each, index) => <GalleryCard3
                imageSrc={each}
                key={index}
                rootClassName="rootClassName"
              />)
            }
          </div>
        </div>
      </div>
      <div className="home-banner">
        <div className="home-banner1">
          <h1 className="home-banner-heading heading2">
            Discover a World of Precious Minerals
          </h1>
          <span className="home-banner-sub-heading">
            Precious Minerals Marketplace
          </span>
          <a href="#home-features">
            <button className="home-banner-button button">Learn More</button>
          </a>
        </div>
      </div>
      <div id="home-faq" className="home-faq">
        <div className="home-faq-container">
          <div className="home-faq1">
            <div className="home-container30">
              <span className="home-text40 sectionTitle">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text43 heading2">Common questions</h2>
              <span className="home-text44">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container31">
              {
                questions.map(each => <Question
                  key={each.id}
                  answer={each.answer}
                  question={each.question}
                />)
              }
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer">
        <footer className="home-footer1">
          <div className="home-container32">
            <img src={Logo} className="home-logo2" alt="Bamitech" />
            <nav className="home-nav1 home-nav1">
              <a href="/" className="home-nav12">Home</a>
              <a href={`${marketplace}/login`} className="home-nav22">Sell Precious Mineral</a>
              <a href={`${marketplace}/login`} className="home-nav32">Buy Precious Minerals</a>
              <a href="#home-faq" className="home-nav42">How It Works</a>
              <span className="home-nav52">
                <ContactFormModal />
              </span>
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container33">
            <span className="home-text61">
              ©{date.getFullYear()} Bamitech, All Rights Reserved. Registered in Ireland Number : 586901
            </span>
            <TermsAndPolicy pdfUrl={doc} setDoc={setDoc} docs={docs} />
            <div className="home-icon-group1">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon10">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon12">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon14">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </footer>
      </div>
      <RegistrationModal plan={plan} open={openSignup} setOpen={setOpenSignup} />
    </div>
  )
}

export default Home
