import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Grid,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Container,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

const RECAPTCHA_SITE_KEY = '6LfKvK8pAAAAANyWFeYEtwEzHaOjzcsSQYher6zR'

const PlanFormModal = ({ plan, open, setOpen }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        company: '',
        mobile: '',
        email: '',
        city: '',
        country: '',
        plan
    });

    useEffect(() => {
        setFormData({ ...formData, plan })
        const timestamp = () => {
            let response = document.getElementById("g-recaptcha-response");
            if (response == null || response.value.trim() == "") {
                let elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);
                elems["ts"] = JSON.stringify(new Date().getTime());
                document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems);
            }
        }

        const interval = setInterval(timestamp, 500);

        return clearInterval(interval)
    }, [plan])

    const [recaptchaToken, setRecaptchaToken] = useState(null);


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit4 = (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            alert('Please complete the reCAPTCHA.');
            return;
        }

        const url = 'https://script.google.com/macros/s/AKfycbzHNWR1fwrikewCvebmI-Rbp9UdXkZHbSZdGrtPh1jTcMziZUL0OxexWb_Geo9LyWzL/exec';

        const dataToSubmit = {
            ...formData,
            recaptchaToken,
        };

        fetch(url, {
            method: 'POST',
            mode: 'no-cors', // Use 'no-cors' to prevent CORS issues
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSubmit)
        }).then((response) => response.json()).then((data) => {
            console.log('Success:', data);
            setOpen(false); // Close the modal on success
        }).catch((error) => {
            console.error('Error:', error);
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            alert('Please complete the reCAPTCHA.');
            return;
        }
    
        const url = 'https://script.google.com/macros/s/AKfycbzHNWR1fwrikewCvebmI-Rbp9UdXkZHbSZdGrtPh1jTcMziZUL0OxexWb_Geo9LyWzL/exec'
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            mode: 'no-cors', // Use 'no-cors' to prevent CORS issues
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            alert('Thank you for creating an account! We have received your information and will contact you as soon as possible.');
            setFormData({
              firstName: '',
              lastName: '',
              company: '',
              mobile: '',
              email: '',
              city: '',
              country: '',
              plan: '',
            });
          } else {
            setFormData({
                firstName: '',
                lastName: '',
                company: '',
                mobile: '',
                email: '',
                city: '',
                country: '',
                plan: '',
              });
            alert('Thank you for creating an account! We have received your information and will contact you as soon as possible.');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred. Please try again.');
        }
      }


    return (
        <Container maxWidth="sm">
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <form>
                    <DialogTitle>Sign Up: {formData.plan} Plan</DialogTitle>
                    <DialogContent>
                        <Grid sx={{ marginTop: '3px' }} container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Company"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth required>
                                    <InputLabel id="plan-label">Plan</InputLabel>
                                    <Select
                                        labelId="plan-label"
                                        name="plan"
                                        value={formData.plan}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Starter">Starter</MenuItem>
                                        <MenuItem value="Basic">Basic</MenuItem>
                                        <MenuItem value="Business">Business</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onChange={handleRecaptchaChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" type="submit">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Container>
    );
};

export default PlanFormModal;
