import React, { useState, useEffect } from 'react';
import GoogleSI from '../assets/web_dark_rd_ctn.svg'
import { useDispatch } from 'react-redux';
import { signup, loginSuccess, signupSuccess } from '../redux/authActions';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logo from '../assets/logo.png'
import './login.css'
import { auth } from '../TrustMe/services/firebase'
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';

const mode = 'login'

const LoginComponent = () => {
    const [mode, setMode] = useState('login')
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const url = window.location.href.split('/')
        const path = url[url.length - 1]
        const newMode = path === 'register' ? 'signup' : 'login'
        if (newMode === 'login') {
            let user = auth.currentUser
            if (user) {
                dispatch(loginSuccess({ email: user.email, id: user.uid }));
            }
        }
        setMode(newMode);
    }, []);

    if (mode === 'signup') {
        history.push('/home#registration');
    }

    const toggleMode = () => {
        const newMode = mode === 'login' ? 'signup' : 'login';
        setMode(newMode);
    }


    return (
        <div>
            <div className={`form-block-wrapper form-block-wrapper--is-${mode}`} ></div>
            <section className={`form-block form-block--is-${mode}`}>
                <header className="form-block__header">
                    <span className="logo">
                    <img src={Logo} alt="Bamitech" />
                    </span>

                    <div className="form-block__toggle-block">
                        <span>{mode === 'login' ? 'Don\'t' : 'Already'} have an account? Click here &#8594;</span>
                        <input id="form-toggler" type="checkbox" onClick={toggleMode} />
                        <label htmlFor="form-toggler"></label>
                    </div>
                </header>
                <LoginForm mode={mode} />
            </section>
        </div>
    )
}

const LoginForm = ({ mode }) => {
    const [user, setUser] = useState({})
    const [hide, setHide] = useState(true)
    const callback = (id, value) => setUser({ ...user, [id]: value })
    const dispatch = useDispatch();
    const history = useHistory();

    const provider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            auth.useDeviceLanguage()
            const resp = await signInWithPopup(auth, provider)
            const u = resp.user
            if (mode === 'login') {
                //Check if a user has an active account
                dispatch(loginSuccess({ email: u.email, id: u.uid }))
            } else {
                dispatch(signup(email, u.displayName))
            }
            history.push('/dashboard');
        } catch (error) {
            console.error('Error logging in:', error)
        }

    };


    return (
        <form onSubmit={async (e) => {
            e.preventDefault()
            try {
                setHide(false)
                if (mode === 'login') {
                    const credentials = await signInWithEmailAndPassword(auth, user.username, user.password)
                    const u = credentials.user
                    dispatch(loginSuccess({ email: u.email, id: u.uid }));

                } else {
                    const resp = await createUserWithEmailAndPassword(auth, user.email, user.createpassword)
                    await updateProfile(resp.user, { displayName: user.fullName })
                    await signup(resp.user)
                    dispatch(signupSuccess({ email: user.email, id: user.uid }));
                }
            } catch (error) {
                alert('Invalid Login Credentials! Please try again.')
            } finally {
                setHide(true)
            }


        }}>
            <div className="form-block__input-wrapper">
                <div className="form-group form-group--login">
                    <Input callback={callback} type="email" id="username" label="email" disabled={mode === 'signup'} />
                    <Input callback={callback} type="password" id="password" label="password" disabled={mode === 'signup'} />
                </div>
                <div className="form-group form-group--signup">
                    <Input callback={callback} type="text" id="fullname" label="full name/company" disabled={mode === 'login'} />
                    <Input callback={callback} type="email" id="email" label="email" disabled={mode === 'login'} />
                    <Input callback={callback} type="password" id="createpassword" label="password" disabled={mode === 'login'} />
                    <Input callback={callback} type="password" id="repeatpassword" label="repeat password" disabled={mode === 'login'} />
                </div>
            </div>
            <button className="button-form button-form--primary full-width" type="submit">{mode === 'login' ? 'Log In' : 'Sign Up'}</button>
            <hr />
            {//Romoved login with Google
             false && ( <a href='' onClick={(e) => {
                    e.preventDefault()
                    signInWithGoogle()
                }}>
                    <img src={GoogleSI} className="full-width button-google" alt="Login with Google" />
                </a>)
            }
            {
                !hide && <LinearProgress color="secondary" />
            }

        </form>
    )
}

const Input = ({ id, type, label, disabled, callback }) => {
    const [value, setValue] = useState('')
    return (
        <input className="form-group__input"
            type={type}
            id={id}
            placeholder={label}
            disabled={disabled}
            value={value}
            onChange={(e) => {
                setValue(e.target.value)
                callback(id, e.target.value)
            }}
        />)
}

export default () => (
    <div className={`app app--is-${mode}`}>
        <LoginComponent
            mode={mode}
            onSubmit={
                function (e) {
                    console.log(e)
                }
            }
        />
    </div>
);

