// src/redux/reducers/authReducer.js
import {ESCROWS_SUCCESS } from './accountActions';


const initialState = {
  escrows:[]
};

const escrowReducer = (state = initialState, action) => {
  switch (action.type) {
    case ESCROWS_SUCCESS:
      return { ...state, escrows: action.payload };
    default:
      return state;
  }
};

export default escrowReducer;