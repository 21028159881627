import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser'
import { Button, TextField, Modal, makeStyles, Snackbar } from '@material-ui/core';
import {IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@material-ui/lab/Alert';
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactFormModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleClose(); // Close the modal after form submission
    setOpenSnackbar(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(!isValidEmail);
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (emailError) {
      return
    }

    emailjs
      .sendForm('service_7k4bkcf', 'template_inhhpsd', form.current, {
        publicKey: 'VKDaDHGw9iNzeaY7b',
      })
      .then(
        () => {
          handleSubmit()
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };


  return (
    <div>
      <span onClick={handleOpen}>Contact Us</span>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <div className={classes.paper}>
           <IconButton
            sx={{ position: 'absolute', top: 1, right: 15, border: '1px solid #0f77d9' }}
            color='error'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <h2>Contact Us</h2>
          <br />
          <p>
            Thank you for reaching out! We're excited to hear from you.<br /> Please fill out the form below, and we'll get back to you as soon as possible
          </p>
          <form ref={form}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              margin="normal"
              name="user_name"
              required
            />
            <TextField
              label="Email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
              name="user_email"
              error={emailError}
              required
            />
            <TextField
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={4}
              fullWidth
              margin="normal"
              name="message"
              required
            />
            <ReCAPTCHA sitekey="6LfKvK8pAAAAANyWFeYEtwEzHaOjzcsSQYher6zR" />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={sendEmail}
              fullWidth
            >
              Submit
            </Button>
          </form>
        </div>
      </Modal>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          Form submitted successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactFormModal;
