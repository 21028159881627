import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute'
import NotFound from '../views/not-found'
import HomePage from './components/dashboard/Dashboard'
import Home from '../views/home'
import Login from '../views/login';
import { Provider } from 'react-redux';
import store from '../redux/store';


const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/home" /> 
                        </Route>
                        <Route path="/home" component={Home} />
                        <PublicRoute path="/register" component={Login} />
                        <PublicRoute path="/login" component={Login} />
                        <PrivateRoute path="/dashboard" component={HomePage} />
                        <Route component={NotFound} path="**" />
                        <Redirect to="**" />
                    </Switch>
                </Router>
            </BrowserRouter>
        </Provider>
    );
};



export default App;
