// src/redux/store.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import accountReducer from './accountReducer';
import escrowReducer from './escrowReducer';

import { configureStore} from '@reduxjs/toolkit'


const rootReducer = combineReducers({
  auth: authReducer,
  account:accountReducer,
  escrow: escrowReducer
});

const store = configureStore({ reducer: rootReducer })

export default store;
