// src/components/UploadForm.js
import React, { useState } from 'react';
import { TextField, Button, Box, IconButton } from '@mui/material';
import { db, storage } from '../../services/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

const UploadForm = ({ handleClose }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [unit, setUnit] = useState('');
    const [images, setImages] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const [hide, setHide] = useState(true)
    console.log(user)

    const handleImageChange = (e) => {
        let files = Array.from(e.target.files)
        files = files.concat(images)
        files = new Set(files)
        files = Array.from(files)
        setImages(files)
        if (files.length) {
            alert('Please upload at least 3 images.');
        }
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setHide(false)
            const imageUrls = [];
            for (const image of images) {
                const imageRef = ref(storage, `images/${user.id}/${image.name}`);
                await uploadBytes(imageRef, image);
                const imageUrl = await getDownloadURL(imageRef);
                imageUrls.push(imageUrl);
            }

            const docRef = await addDoc(collection(db, 'products', `${user.id}`, 'inventory'), {
                title,
                description,
                price,
                unit,
                imageUrls,
                timestamp: Date.now(),
                active: true
            });

            setTitle('');
            setDescription('');
            setPrice('');
            setUnit('');
            setImages([]);
            alert('Product uploaded successfully! ' + docRef.id);

        } catch (err) {
            alert('Uploaded Failed! Please try again');
        } finally {
            setHide(true)
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {
                hide && <IconButton
                    sx={{ position: 'absolute', top: 1, right: 3, border: '1px solid #0f77d9' }}
                    color='error'
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            }
            <TextField
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
            />
            <ReactQuill value={description} onChange={setDescription} placeholder="Enter the product description here..." />
            <TextField
                label="Price (EUR)"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                type="number"
                inputProps={{ min: "0", step: "0.01" }}
            />
            <TextField
                label="Unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                required
            />
            <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageChange}
            />
            {
                hide && <Button type="submit" variant="contained" color="primary" disabled={images.length < 3}>
                    Add Inventory
                </Button>
            }
            {
                !hide && <LinearProgress color="secondary" />
            }
        </Box>
    );
};

export default UploadForm;
